import React, { ReactElement } from 'react';
import { default as MuiTreeItem, TreeItemProps } from '@mui/lab/TreeItem';
import { default as MuiTreeView, TreeViewProps } from '@mui/lab/TreeView';
import { styled } from '@mui/material';
const StyledMuiTreeItem = styled(MuiTreeItem)(({
  theme
}) => ({
  '& .MuiTreeItem-content': {
    borderRadius: theme.shape.borderRadius2x,
    padding: '16px 20px !important',
    width: 'unset !important'
  },
  '& .MuiTreeItem-content.Mui-selected': {
    background: theme.palette.info.main
  },
  '& .MuiTreeItem-content.Mui-selected.Mui-focused': {
    background: theme.palette.info.main
  },
  '& .MuiTreeItem-content.Mui-selected:hover': {
    background: theme.palette.info.light
  },
  '& .Mui-selected .MuiTreeItem-label': {
    fontWeight: '400',
    color: theme.palette.primary.contrastText
  },
  '.MuiTreeItem-label': {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  '&.MuiTreeItem-root': {
    color: 'rgba(0, 0, 0, 0.56)'
  },
  '&.MuiTreeItem-root .MuiSvgIcon-colorPrimary': {
    color: 'rgba(4, 6, 16, 1)'
  }
}));
export const TreeItem: React.FC<TreeItemProps & {
  selected?: string[];
}> = ({
  icon,
  selected,
  children,
  ...restProps
}) => {
  let highlightableIcon = null;
  let iconProps = {};
  const isSelected = selected && selected.includes(restProps.nodeId);
  if (isSelected) {
    iconProps = {
      color: 'primary'
    };
  }
  if (icon) {
    highlightableIcon = React.cloneElement((icon as ReactElement), iconProps);
  }
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement((child as ReactElement), {
      selected
    });
  });
  return <StyledMuiTreeItem icon={highlightableIcon} {...restProps} data-sentry-element="StyledMuiTreeItem" data-sentry-component="TreeItem" data-sentry-source-file="TreeView.tsx">
      {childrenWithProps}
    </StyledMuiTreeItem>;
};
export const TreeView: React.FC<TreeViewProps> = ({
  children,
  selected,
  ...restProps
}) => {
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement((child as ReactElement), {
      selected
    });
  });
  return <MuiTreeView selected={(selected as string & string[])} {...restProps} data-sentry-element="MuiTreeView" data-sentry-component="TreeView" data-sentry-source-file="TreeView.tsx">
      {childrenWithProps}
    </MuiTreeView>;
};
export type { TreeItemProps, TreeViewProps };