import {
  formatDateFilterPayload,
  formatToFilterPayload,
  generateAuthHeader,
} from '#/api/utils';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import qs from 'qs';
import { exportApi } from './export-api.service';
import { FacilitiesFormData } from '#/components/Facility/FilterForm';
import { isEmpty, pickBy } from 'lodash';

export const exportApiInject = exportApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadSelectedCredWorkflowsCsv: builder.mutation<unknown, Array<string>>({
      query: (workflowIds: string[]) => ({
        url: '/credentialing-workflows/export-legacy',
        method: 'post',
        data: { workflowIds },
        responseType: 'blob',
        headers: generateAuthHeader(),
      }),
    }),
    downloadSelectedCredWorkflowsFeatureFlagCsv: builder.mutation<
      unknown,
      Array<string>
    >({
      query: (workflowIds: string[]) => ({
        url: '/credentialing-workflows/export',
        method: 'post',
        data: { workflowIds },
        headers: generateAuthHeader(),
      }),
    }),
    downloadFacilitiesCSV: builder.mutation<unknown, FacilitiesFormData>({
      query: (filterData) => ({
        url: '/facilities/export',
        params: pickBy(filterData),
        method: 'get',
        responseType: 'blob',
        headers: generateAuthHeader(),
      }),
    }),
    downloadFacilityCredWorkflowsCsv: builder.mutation<
      unknown,
      FacilitiesFormData
    >({
      query: (filterData) => ({
        url: '/facility-credentialing-workflows/export',
        params: pickBy(filterData),
        method: 'get',
        responseType: 'blob',
        headers: generateAuthHeader(),
      }),
    }),
    downloadSelectedMonitoringWorkflowsCsv: builder.mutation<
      unknown,
      Array<string>
    >({
      query: (workflowIds: string[]) => ({
        url: '/monitoring-workflows/export-legacy',
        method: 'post',
        data: { workflowIds },
        responseType: 'blob',
        headers: generateAuthHeader(),
      }),
    }),
    downloadSelectedMonitoringWorkflowsFeatureFlagCsv: builder.mutation<
      unknown,
      Array<string>
    >({
      query: (workflowIds: string[]) => ({
        url: '/monitoring-workflows/export',
        method: 'post',
        data: { workflowIds },
        headers: generateAuthHeader(),
      }),
    }),
    downloadSelectedProvidersCsv: builder.mutation<unknown, Array<string>>({
      query: (providerIds: string[]) => ({
        url: '/providers/export-legacy',
        method: 'post',
        data: { providerIds, extended: false },
        responseType: 'blob',
        headers: generateAuthHeader(),
      }),
    }),
    downloadSelectedProvidersFeatureFlagCsv: builder.mutation<
      unknown,
      Array<string>
    >({
      query: (providerIds: string[]) => ({
        url: '/providers/export',
        method: 'post',
        data: { providerIds, extended: false },
        headers: generateAuthHeader(),
      }),
    }),
    downloadCredentialingCsv: builder.mutation<
      unknown,
      {
        formData?: ProviderFormData;
      }
    >({
      query: ({ formData }) => {
        // todo remove qs and add params
        let url = `/credentialing-workflows/export-legacy`;
        if (formData) {
          formatToFilterPayload(formData);
          const formattedFormData = formatDateFilterPayload(formData);
          url += `?${qs.stringify(formattedFormData, {
            filter: (_, value) => (value ? value : undefined),
          })}`;
        }

        return {
          url,
          method: 'GET',
          responseType: 'blob',
          headers: generateAuthHeader(),
        };
      },
      invalidatesTags: [],
    }),
    downloadCredentialingFeatureFlagCsv: builder.mutation<
      unknown,
      {
        formData?: ProviderFormData;
        isWorkflowEnabled?: boolean;
        type?: string;
      }
    >({
      query: ({ formData, type, isWorkflowEnabled }) => {
        let formattedFormData = {};
        const url = `/credentialing-workflows/${
          isWorkflowEnabled ? 'export-workflow' : 'export'
        }`;

        if (formData) {
          formatToFilterPayload(formData);
          formattedFormData = formatDateFilterPayload(formData);
        }
        return {
          url,
          method: 'GET',
          headers: generateAuthHeader(),
          params: pickBy({ ...formattedFormData, type: type }),
        };
      },
      invalidatesTags: [],
    }),
    downloadProvidersCsv: builder.mutation<
      unknown,
      {
        formData?: ProviderFormData;
        extended?: boolean;
      }
    >({
      query: ({ formData, extended }) => {
        let url = `/providers/export-legacy`;
        if (formData) {
          // todo remove qs and add params
          url += `?${qs.stringify(
            { ...formData, extended: Boolean(extended) },
            {
              filter: (_, value) => (value ? value : undefined),
            },
          )}`;
        }

        return {
          url: url,
          method: 'get',
          headers: generateAuthHeader(),
        };
      },
    }),
    downloadProvidersFeatureFlagCsv: builder.mutation<
      unknown,
      {
        formData?: ProviderFormData;
        extended?: boolean;
        type?: string;
        isWorkflowEnabled?: boolean;
      }
    >({
      query: ({ formData, extended, type, isWorkflowEnabled }) => {
        let url = `/providers/${
          isWorkflowEnabled ? 'export-workflow' : 'export'
        }`;
        const params: ProviderFormData = {};

        if (formData) {
          Object.entries(formData).forEach(([key, val]) => {
            if (Boolean(val) && !isEmpty(val)) {
              params[key as keyof typeof formData] = val;
            }
          });
        }

        return {
          url: url,
          method: 'get',
          params: pickBy({
            ...params,
            extended: Boolean(extended),
            type: type,
          }),
          headers: generateAuthHeader(),
        };
      },
    }),
  }),
});

export const {
  useDownloadSelectedCredWorkflowsCsvMutation,
  useDownloadSelectedMonitoringWorkflowsCsvMutation,
  useDownloadSelectedProvidersCsvMutation,
  useDownloadCredentialingCsvMutation,
  useDownloadCredentialingFeatureFlagCsvMutation,
  useDownloadSelectedMonitoringWorkflowsFeatureFlagCsvMutation,
  useDownloadSelectedCredWorkflowsFeatureFlagCsvMutation,
  useDownloadSelectedProvidersFeatureFlagCsvMutation,
  useDownloadProvidersCsvMutation,
  useDownloadProvidersFeatureFlagCsvMutation,
  useDownloadFacilitiesCSVMutation,
  useDownloadFacilityCredWorkflowsCsvMutation,
} = exportApiInject;
