import { FlagOptions } from '../../types';

export const FEATURE_FLAG_KEYS: FlagOptions[] = [
  'enable_licensing_workflows',
  'payor_enrollment',
  'test_flag',
  'open_selected_files',
  'new_exports',
  'show_pipeline_data_verifications',
  'facilities_mvp',
  'async_psv_generation',
  'org_settings_search_users',
  'rosteros',
  'enrollments',
  'rdb_users_organizations',
  'rdb_providers',
  'flags_dashboard',
  'all_org_pages',
  'new_psv_requirements',
  'payor_enrollment',
  'inbound_outreach',
  'wellspan_poc',
  'pipeline_status_ui',
  'clean_non_clean_configuration',
];

export function isFeatureFlagKey(key: string): key is FlagOptions {
  return FEATURE_FLAG_KEYS.includes(key as FlagOptions);
}
