import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { Notification } from '#/types';

export const notificationsApi = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotifications: builder.query<
      { data: Notification[]; count: number },
      void
    >({
      query: () => ({
        url: '/notifications',
        method: 'get',
      }),
      providesTags: [TAGS_MAP.NOTIFICATIONS],
    }),
    markAsRead: builder.mutation<void, string>({
      query: (id) => ({
        url: `/notifications/${id}/mark-as-read`,
        method: 'patch',
      }),
      invalidatesTags: [TAGS_MAP.NOTIFICATIONS],
    }),
  }),
});

export const {
  useFetchNotificationsQuery,
  useLazyFetchNotificationsQuery,
  useMarkAsReadMutation,
} = notificationsApi;
