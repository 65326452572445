import { HelpOutline } from '@mui/icons-material';
import { IconButton, Modal, Card, Typography, TextField, Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
export const HelpFormModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    enqueueSnackbar
  } = useSnackbar();
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const formData = new URLSearchParams();
      formData.append('name', event.target.name.value);
      formData.append('email', event.target.email.value);
      formData.append('subject', event.target.subject.value);
      formData.append('description', event.target.description.value);
      formData.append('reason', event.target.reason.value);
      const response = await fetch('/api/salesForce', {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        enqueueSnackbar('Form submitted successfully!', {
          variant: 'success'
        });
      } else {
        console.error('Form submission failed:', response.statusText);
        enqueueSnackbar('Form submission failed!', {
          variant: 'error'
        });
      }
    } catch (error) {
      console.error('Form submission error');
      enqueueSnackbar('Form submission failed!', {
        variant: 'error'
      });
    }
    setIsModalOpen(false);
  };
  return <>
      {/* Help button */}
      <IconButton size="large" onClick={handleOpenModal} data-sentry-element="IconButton" data-sentry-source-file="SalesForceHelpModal.tsx">
        <HelpOutline data-sentry-element="HelpOutline" data-sentry-source-file="SalesForceHelpModal.tsx" />
      </IconButton>
      <Modal open={isModalOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="SalesForceHelpModal.tsx">
        <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4
      }} data-sentry-element="Card" data-sentry-source-file="SalesForceHelpModal.tsx">
          <Typography variant="h5" sx={{
          mb: 2
        }} data-sentry-element="Typography" data-sentry-source-file="SalesForceHelpModal.tsx">
            Need Help?
          </Typography>
          <form id="helpForm" onSubmit={handleSubmit}>
            <TextField name="name" label="Name" variant="outlined" fullWidth margin="normal" data-sentry-element="TextField" data-sentry-source-file="SalesForceHelpModal.tsx" />
            <TextField name="email" label="Email" variant="outlined" fullWidth margin="normal" data-sentry-element="TextField" data-sentry-source-file="SalesForceHelpModal.tsx" />
            <FormControl variant="outlined" fullWidth margin="normal" data-sentry-element="FormControl" data-sentry-source-file="SalesForceHelpModal.tsx">
              <InputLabel id="reason-label" data-sentry-element="InputLabel" data-sentry-source-file="SalesForceHelpModal.tsx">Request Type</InputLabel>
              <Select name="reason"
            // labelId="reason-label"
            label="Request Type" variant="outlined" fullWidth data-sentry-element="Select" data-sentry-source-file="SalesForceHelpModal.tsx">
                <MenuItem value="Product Bug" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Product Bug</MenuItem>
                <MenuItem value="Feature Request" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Feature Request</MenuItem>
                <MenuItem value="General Question" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">General Question</MenuItem>
                <MenuItem value="Billing Issue" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Billing Issue</MenuItem>
                <MenuItem value="User Access" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">User Access</MenuItem>
                <MenuItem value="Service Disruption" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">
                  Service Disruption
                </MenuItem>
                <MenuItem value="License" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">License</MenuItem>
                <MenuItem value="NPDB" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">NPDB</MenuItem>
                <MenuItem value="Malpractice" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Malpractice</MenuItem>
                <MenuItem value="CAQH" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">CAQH</MenuItem>
                <MenuItem value="DEA/CDA" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">DEA/CDA</MenuItem>
                <MenuItem value="Work History" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Work History</MenuItem>
                <MenuItem value="Requesting File Update" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">
                  Requesting File Update
                </MenuItem>
                <MenuItem value="Second Request" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Second Request</MenuItem>
                <MenuItem value="File Rush Request" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">File Rush Request</MenuItem>
                <MenuItem value="Outreach" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">Outreach</MenuItem>
                <MenuItem value="Board Certification" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">
                  Board Certification
                </MenuItem>
                <MenuItem value="Withdraw/Cancel File" data-sentry-element="MenuItem" data-sentry-source-file="SalesForceHelpModal.tsx">
                  Withdraw/Cancel File
                </MenuItem>
              </Select>
            </FormControl>
            <TextField name="subject" label="Subject" variant="outlined" fullWidth margin="normal" data-sentry-element="TextField" data-sentry-source-file="SalesForceHelpModal.tsx" />
            <TextField name="description" label="Description" variant="outlined" fullWidth multiline rows={4} margin="normal" data-sentry-element="TextField" data-sentry-source-file="SalesForceHelpModal.tsx" />
            <Button type="submit" variant="contained" color="primary" data-sentry-element="Button" data-sentry-source-file="SalesForceHelpModal.tsx">
              Submit
            </Button>
          </form>
        </Card>
      </Modal>
    </>;
};